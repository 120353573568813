<template>
  <v-container id="jobseeker" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + require('../../assets/images/jobseeker/job-seeker-bg.jpg') + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-secondary--text text-uppercase" style="-webkit-text-stroke: 0.2px white;">{{
              $t("opportunities")
            }}</h1>
          </v-col>
        </v-row>
        <transition name="fade" mode="out-in" v-if="opportunity_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="6"
              md="3"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="opportunities.length==0 && !opportunity_loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="opportunities.length && !opportunity_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center" :key="itemsperpage * page">
            <v-col
              v-for="(item, i) in opportunities.slice(
                itemsperpage * (page - 1),
                itemsperpage * page
              )"
              :key="i"
              cols="12"
              sm="6"
              md="3"
            >
              <v-card
                class="elevation-0"
                outlined
                @click="ViewOpportunity(item)"
              >
                <div
                  class="text-center text-sm-left"
                >
                  <div>
                    <v-card-title class="mb-2 custom-accent--text"><span v-line-clamp="1">{{$i18n.locale == "en" ? item.titleEN : item.titleFR}}</span></v-card-title>
                    <v-card-subtitle class="subtitle-2">{{$t("by")}} <span class="text-capitalize">{{$getObjectById(users,item.author).name}}</span> | {{item.created_at | formatDate}}</v-card-subtitle>
                    <v-card-text class="py-0"><span class="v-html-line-clamp-4" v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span></v-card-text>
                    <v-card-actions>
                      <v-btn color="custom-accent" class="ml-auto" text @click.stop="ViewOpportunity(item)">{{$t("applyforjob")}}</v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0 mb-8">
          <v-col v-if="Math.ceil(opportunities.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(opportunities.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
        <v-dialog v-model="opportunitydetailsoverlay" max-width="800px" scrollable>
          <v-card class="pa-2 overflow-hidden" v-if="activeopportunity">
            <v-card-title class="pa-0 ma-0 pa-4 mr-6">
              <span class="text-h5 custom-accent--text">{{
                $i18n.locale == "en"
                  ? activeopportunity.titleEN
                  : activeopportunity.titleFR
              }}</span>
              <v-tooltip left color="custom-secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    absolute
                    top
                    right
                    style="z-index: 3;"
                    v-bind="attrs"
                    v-on="on"
                    :title="$t('close')"
                    @click="opportunitydetailsoverlay = false"
                    icon
                  >
                    <v-icon large>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("close") }}</span>
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle class="subtitle-2 pa-0 ma-0 mx-4 custom-secondary--text">{{$t("by")}} <span class="text-capitalize">{{$getObjectById(users,activeopportunity.author).name}}</span> | {{activeopportunity.created_at | formatDate}}</v-card-subtitle>
            <v-divider class="my-4"></v-divider>
            <v-card-text class="subtitle-2 custom-secondary--text">
              <span v-html="$i18n.locale == 'en'? activeopportunity.descriptionEN: activeopportunity.descriptionFR"></span>
              <span class="text-uppercase text-h6 custom-accent--text">{{$t("applyforjob")}}</span>
              <contact-us-form class="my-5" :isDark="false" :type="'jobseeker'"></contact-us-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../../components/ContactUsForm.vue';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "JobSeeker",

  data() {
    return {
      page: 1,
      itemsperpage: 4,
      opportunitydetailsoverlay: false,
      activeopportunity: null,
    };
  },
  components: {'contact-us-form':contactusform,},
  async created() {
    await this.retrieveUsers();
    await this.retrieveOpportunities().then(()=>{
      this.opportunities.reverse();
    });
  },
  computed: {
    ...mapGetters({users:'getUsers'}),
    ...mapGetters('opportunity', {
      opportunities:'getOpportunities'
    }),
    ...mapState("opportunity", {
      opportunity_loading: "loading"
    }),
  },
  watch: {
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    ViewOpportunity(opportunity) {
      this.opportunitydetailsoverlay = true;
      this.activeopportunity = opportunity;
    },

    ...mapActions(["retrieveUsers"]),
    ...mapActions("opportunity", ["addOpportunity","retrieveOpportunities"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/jobseeker.css");
</style>